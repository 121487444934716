<template>
  <!--begin::Employees-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.EMPLOYEES") }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.EMPLOYEES_DESC") }} {{ totalEmployees }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
<!--        <b-button v-b-modal.modal-1-->
<!--                  class="btn bg-light-primary font-weight-bolder font-size-sm">-->
<!--          <i class="fas fa-plus icon-md"></i>Добавить сотрудника-->
<!--        </b-button>-->
        <!--end::Button-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin: Search Form-->
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="col-lg-10 col-xl-10">
            <div class="row align-items-center">
              <div class="col-md-3 my-2 my-md-0">
                <div class="input-icon">
                  <input type="text" class="form-control" v-model="filter_name" :placeholder="$t('SELECT.SEARCH')" />
                  <span><i class="flaticon-search text-muted"></i></span>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">{{ $t("TITLE.ROLES_TITLE") }}</label>
                  <treeselect v-model="filter_roles"
                              :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                              :multiple="true"
                              :normalizer="normalizer"
                              :limit="1"
                              :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                              :options="rolesList">
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.slice(0, 5) }}</div>
                  </treeselect>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">{{ $t("TITLE.GROUPS_TITLE") }}</label>
                  <treeselect v-model="filter_groups"
                              :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                              :multiple="true"
                              :normalizer="normalizer2"
                              :limit="1"
                              :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                              :options="groupsList">
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.slice(0, 5) }}</div>
                  </treeselect>
                </div>
              </div>
              <div class="col-md-3 my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">{{ $t("TITLE.STATUSES_TITLE") }}</label>
                  <treeselect v-model="filter_statuses"
                              :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                              :multiple="true"
                              :normalizer="normalizer3"
                              :limit="1"
                              :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                              :options="statusesList">
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.slice(0, 5) }}</div>
                  </treeselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--begin::Button-->

<!--      <div class="row align-items-center">-->
<!--        <div class="col-md-3 my-2 my-md-0">-->
<!--          <b-button v-if="checked"-->
<!--              v-b-modal.modal-1-->
<!--              class="btn bg-light font-weight-bolder font-size-sm">-->
<!--          Добавить в группу</b-button>-->
<!--        </div>-->
<!--      </div>-->

      <!--end::Button-->
      <!--end: Search Form-->
      <!--begin::Table-->
      <div class="table-responsive">
        <b-table
            id="employees-table"
            class="table-vertical-center"
            show-empty
            :items="employeesList"
            :fields="fields"
            :per-page="0"
            :current-page="employeesListPagination.page">
          <template #empty="scope">
            <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
          </template>
          <template #head(check)="data">
            <label class="checkbox checkbox-single mr-2">
              <input
                  type="checkbox"
                  @input="setCheckAll($event.target.checked)"
              />
              <span></span>
            </label>
          </template>
          <template #head()="data">
            <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
          </template>
          <template #head(actions)="data">
            <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
          </template>
          <template #cell()="data">
            <label class="checkbox checkbox-single">
              <input type="checkbox" :value="data.item.id" :checked="checked" @input="setCheck($event.target.checked, data.item.id)" />
              <span></span>
            </label>
          </template>
          <template #cell(name)="data">
            <router-link :disabled="!isCanEdit"
                         :event="isCanEdit ? 'click' : ''"
                         :style="isCanEdit ? 'text-decoration: underline': ''"
                         :to="{ name: 'employee', params: { id: data.item.id } }">
              <div class="d-flex align-items-center">
                <div v-if="data.item.profile && data.item.profile.photo"
                     class="symbol symbol-50 mr-2">
                  <img class="symbol-label"
                       :src="`data:image/png;base64,${data.item.profile.photo}`" alt="avatar_img" />
                </div>
                <div v-else class="label label-lg bg-light-primary font-weight-bolder label-inline mr-2 py-8 px-6 float-left">
                  {{ data.item.name.first.charAt(0).toUpperCase() + "" + data.item.name.last.charAt(0).toUpperCase() }}
                </div>
                <div style="min-width: 200px; overflow-x: auto" class="float-left">
                  <p class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mt-2 mb-0">{{ data.item.name.display }}</p>
                  <span>{{ data.item.email }}</span>
                </div>
              </div>
            </router-link>
          </template>
          <template #cell(role)="data">
            <span v-for="(role, i) in data.item.roles" :key="i" class="label label-lg label-inline m-1" :title="role.name">{{ role.name.slice(0, 10) }}</span>
          </template>
          <template #cell(group)="data">
            <span v-for="(group, i) in data.item.groups" :key="i" class="label label-lg label-inline m-1" :title="group.name">{{ group.name.slice(0, 10) }}</span>
          </template>
<!--    РЕАЛИЗОВАТЬ ДОСТУП ЧЕРЕЗ РОЛЕВУЮ МОДЕЛЬ      -->
<!--          <template #cell(actions)="data">-->
<!--          <span class="float-right">-->
<!--            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"-->
<!--                      v-b-modal.modal-1-->
<!--                      v-b-tooltip.hover.v-dark="'Настроить'">-->
<!--              <Settings/>-->
<!--            </b-button>-->
<!--            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"-->
<!--                      v-b-modal.modal-1-->
<!--                      v-b-tooltip.hover.v-dark="'Активировать'">-->
<!--              <AddUser/>-->
<!--            </b-button>-->
<!--            <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"-->
<!--                      v-b-modal.modal-1-->
<!--                      v-b-tooltip.hover.v-dark="'Деактивировать'">-->
<!--              <DeleteUser/>-->
<!--            </b-button>-->
<!--          </span>-->
<!--          </template>-->
        </b-table>
      </div>
      <!--end::Table-->
      <!--begin: Pagination-->
      <Pagination
          name="employees-table"
          :pagination="employeesListPagination"
          @changePageLimit="changePageLimit">
      </Pagination>
      <!--end: Pagination-->
    </div>
    <!--end::Body-->
    <!--begin::Modal-->
    <b-modal id="modal-1" title="Title">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
    <!--end::Modal-->
  </div>
  <!--end::Employees-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_EMPLOYEES_LIST,
  GET_ROLES_LIST,
  GET_GROUPS_LIST
} from "@/core/services/store/management.module";

import Settings from "@/view/layout/svg/Settings";
import AddUser from "@/view/layout/svg/AddUser";
import DeleteUser from "@/view/layout/svg/DeleteUser";
import Pagination from "@/view/layout/pagination/Pagination";

export default {
  name: "Employees",
  components: {
    Settings,
    AddUser,
    DeleteUser,
    Pagination
  },
  mounted() {
    this.$store.dispatch(GET_EMPLOYEES_LIST).then(() => {
      this.totalEmployees = this.employeesListPagination.total;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MANAGEMENT.EMPLOYEES") }]);
      localStorage.setItem("pageNumber", JSON.stringify({ page: 1, limit: 20 }));
    });
    this.$store.dispatch(GET_ROLES_LIST);
    this.$store.dispatch(GET_GROUPS_LIST);
  },
  data() {
    return {
      totalEmployees: 0,
      normalizer(node) {
        return {
          id: node.name,
          label: node.name,
          // children: node.subOptions,
        }
      },
      normalizer2(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      normalizer3(node) {
        return {
          id: node.value,
          label: node.name,
        }
      },
      filter_name: '',
      filter_roles: null,
      filter_groups: null,
      filter_statuses: null,
      filters: {
        name: '',
        roles: null,
        groups: null,
        statuses: null
        // email: null
      },
      checked: false,
      statusesList: [
        { name: this.$t("MANAGEMENT.EMPLOYEE_ACTIVE_STATUS"), value: "active" },
        { name: this.$t("MANAGEMENT.EMPLOYEE_WAIT_STATUS"), value: "wait" },
        { name: this.$t("MANAGEMENT.EMPLOYEE_BLOCKED_STATUS"), value: "blocked"}
      ],
      fields: [
        { key: 'check', label: ' ' },
        { key: 'name', label: this.$t("MANAGEMENT.LAST_NAME_TABLE_TITLE") },
        { key: 'role', label: this.$t("MANAGEMENT.ROLES_TABLE_TITLE") },
        { key: 'group', label: this.$t("MANAGEMENT.GROUPS_TABLE_TITLE") },
        // { key: 'actions', label: 'Действия' }
      ],
    }
  },
  watch: {
    filter_name(val) {
        this.filters.name = val;
        this.$store.dispatch(GET_EMPLOYEES_LIST, this.filters);
    },
    filter_roles(val) {
      if (val.length > 0) {
        this.filters.roles = val.join(',');
        this.$store.dispatch(GET_EMPLOYEES_LIST, this.filters);
      } else {
        this.filters.roles = null;
        this.$store.dispatch(GET_EMPLOYEES_LIST);
      }
    },
    filter_groups(val) {
      if (val.length > 0) {
        this.filters.groups = val.join(',');
        this.$store.dispatch(GET_EMPLOYEES_LIST, this.filters);
      } else {
        this.filters.groups = null;
        this.$store.dispatch(GET_EMPLOYEES_LIST);
      }
    },
    filter_statuses(val) {
      if (val.length > 0) {
        this.filters.statuses = val.join(',');
        this.$store.dispatch(GET_EMPLOYEES_LIST, this.filters);
      } else {
        this.filters.statuses = null;
        this.$store.dispatch(GET_EMPLOYEES_LIST);
      }
    }
  },
  computed: {
    ...mapGetters([
      "employeesList",
      "employeesListPagination",
      "rolesList",
      "groupsList"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
  },
  methods: {
    changePageLimit(val) {
      localStorage.setItem("pageNumber", JSON.stringify(val));
      this.$store.dispatch(GET_EMPLOYEES_LIST, val);
    },
    setCheckAll(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },
    setCheck(check, id) {
      console.log(check, id)
    }
  }
}
</script>

<style lang="scss">
  //.vue-treeselect__multi-value-item-container {
  //  width: 120%;
  //  overflow: hidden;
  //}
</style>
